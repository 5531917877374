<template>
  <div :class="$style.home">
    <div :class="$style.info">
      <p :class="$style.name">{{ info.name }}</p>
      <BjTag :class="$style.tag" :type="info.collect_status === 1 ? 'primary' : 'default'">{{ state }}</BjTag>
    </div>
    <div :class="$style.table">
      <p :class="$style.title">
        登记信息
        <!-- <BjButton :class="$style.right" type="primary">
          <i class="ri-download-line" />
          导出数据
        </BjButton> -->
      </p>
      <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <template #user="item">
          <img :class="$style.cover" :src="item.user.avatar" />
          <span class="primary">{{ item.user.nickname }}</span>
        </template>
        <template #action="item">
          <bj-link type="primary" @click="onDetail(item)"> 详情 </bj-link>
          <a-popconfirm
            title="确定要删除表单吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。"
            @confirm="onDelete(item)"
          >
            <bj-link class="ml-4" type="danger"> 删除 </bj-link>
          </a-popconfirm>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>

    <bj-modal :class="$style.modal" title="详情" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <div>
        <div :class="$style.sn">
          <div :class="$style.snleft">
            <BjTag :type="detail.status === 0 || detail.status === 1 ? 'primary' : 'default'">{{
              detail.status | statusName
            }}</BjTag>
            <div>SN: {{ detail.sn }}</div>
          </div>
          <div v-if="detail.status === 1" :class="$style.snright">
            <BjButton type="primary" @click="writeOff()"> 立即核销 </BjButton>
            <BjButton class="ml-20" @click="onUnsubscribe()"> 预订退订 </BjButton>
          </div>
        </div>
        <div :class="$style.line" />
        <div :class="$style.box">
          <div :class="$style.left">提交用户</div>
          <div :class="$style.right">
            <img :src="detail.user.avatar" />
            <span class="primary">{{ detail.user.nickname }}</span>
          </div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">登记编号</div>
          <div :class="$style.right">
            {{ detail.sign_no }}
          </div>
        </div>

        <div :class="$style.box">
          <div :class="$style.left">登记时间</div>
          <div :class="$style.right">
            {{ detail.created_at }}
          </div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">报名费用</div>
          <div :class="$style.right">{{ detail.pay_amount }} 元</div>
        </div>
        <div :class="$style.line" />
        <div v-for="(item, index) in info.table" :key="index" :class="$style.box">
          <div :class="$style.left">{{ item.title }}</div>
          <div :class="$style.right">{{ detail[item.dataIndex] }}</div>
        </div>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { appFormServce } from '@/service'

const service = new appFormServce()

export default {
  name: 'detail',
  filters: {
    statusName(val) {
      const obj = {
        0: '待支付',
        1: '未核销',
        2: '已核销',
        3: '已退订',
        4: '已过期',
      }
      return obj[val]
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      data: [],
      info: {},
      id: '',
      visible: false,
      columns: [
        {
          title: '提交用户',
          scopedSlots: {
            customRender: 'user',
          },
        },
        { title: '登记编号', dataIndex: 'sign_no' },
        { title: '提交时间', dataIndex: 'created_at' },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      detail: {
        user: {},
      },
    }
  },
  computed: {
    state() {
      return this.info.collect_status === 1 ? '收集中' : '已结束'
    },
  },
  async created() {
    this.id = this.$route.query.id
    if (!this.id) {
      this.$router.push({
        name: 'appFormManage',
      })
    }
    await this.getInfo()
    await this.getList()
  },
  methods: {
    async getInfo() {
      const { data } = await service.getSimple({
        id: this.id,
      })
      this.info = data
      if (this.info.table.length) {
        this.columns.splice(3, 0, ...this.info.table)
      }
    },
    async getList() {
      const { data } = await service.getListUser({
        page: this.page,
        page_size: this.pageSize,
        form_id: this.id,
      })
      this.data = data.record
      this.total = data.total
    },
    onDetail(item) {
      this.detail = item
      this.visible = true
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    async onDelete(item) {
      await service.delete({
        id: item.id,
      })
      this.getList()
      this.$message.success('删除成功')
    },
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
    async writeOff() {
      try {
        await service.writeOff({
          sn: this.detail.sn,
        })
        this.getList()
        this.$message.success('操作成功')
        this.visible = false
      } catch (e) {}
    },
    async onUnsubscribe() {
      try {
        await service.unsubscribe({
          id: this.detail.id,
        })
        this.getList()
        this.$message.success('操作成功')
        this.visible = false
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.home {
  .info {
    padding: 20px 14px;
    background: #fff;

    .name {
      margin-bottom: 4px;
      color: #000;
      font-size: 16px;
    }

    .tag {
      font-size: 12px;
    }
  }

  .table {
    min-height: calc(100vh - 252px);
    margin-top: 20px;
    padding: 20px;
    background: #fff;

    .title {
      position: relative;
      color: #000;
      font-size: 16px;
    }

    .right {
      position: absolute;
      right: 0;
    }

    // .cover {
    //   display: flex;
    //   align-items: center;
    //   max-width: 250px;
    //   color: #000;
    //   font-size: 14px;

    //   .img {
    //     width: 32px;
    //     height: 32px;
    //     margin-right: 10px;
    //     border-radius: 6px;
    //     object-fit: cover;
    //   }
    // }
  }

  .cover {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 10px;
  }
}

.modal {
  .sn {
    display: flex;
    .snleft {
      width: 240px;
      font-size: 12px;
      color: #5c5c5c;
    }
    .snright {
      text-align: right;
      flex: 1;
    }
  }

  .line {
    width: 100%;
    border-bottom: 1px solid #eee;
    margin: 20px 0;
  }

  .box {
    display: flex;
    font-size: 14px;
    line-height: 32px;

    .left {
      width: 100px;
    }

    .right {
      flex: 1;

      img {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        object-fit: cover;
        margin-right: 10px;
      }
    }
  }
}
</style>
